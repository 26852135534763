@import "../../variables";
@import "../../mixins";

.pdf {
  display: flex;
  justify-content: space-around;
}

.resume {
  text-align: center;
  font-family: MomcakeBold;
  color: $main-text;
  font-size: 20px;
  h1 {
    text-decoration: underline;
    margin-bottom: 50px;
    color: $highlighter;
  }
}

.download-div {
  display: flex;
  flex-direction: column;
  p {
    font-size: 24px;
  }
}

.bottom-arrow {
  margin-top: 20px;
}

.download-button:hover,
.download-button:focus,
.download-button:after {
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.glow-div {
  font: 6vw/1 Permanent Marker;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: snow;
  span {
    animation: glow $d ease-in-out both infinite;
    @for $i from 1 through $chars {
      &:nth-child(#{$i}) {
        animation-delay: $i/$chars * -$d;
      }
    }
  }
}

@keyframes glow {
  0%,
  100% {
    @include glow(nth($c, 1), nth($c, 2), nth($c, 3));
  }
  33% {
    @include glow(nth($c, 2), nth($c, 3), nth($c, 1));
  }
  66% {
    @include glow(nth($c, 3), nth($c, 1), nth($c, 2));
  }
}

@media (max-width: $tablet-max) {
  .pdf {
    flex-direction: column-reverse;
    // width: 50px;
  }

  .glow-div {
    font: 12vw/1 Permanent Marker;
    flex-direction: row;
  }

  .resume-div {
    margin: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  .PDFPageOne {
    margin-bottom: 25px;
  }

  .PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
  }
}
