@import "../../variables";

.about {
  height: 800px;
  display: flex;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 104%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: black;
  .about-p {
    width: 75%;
    font-family: MomcakeThin;
    color: white;
    font-size: 26px;
    line-height: 1.15;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.about-img {
  width: 1250px;
  height: 1250px;
}

.career-title {
  color: $highlighter;
  font-size: 38px;
  font-family: MomcakeBold;
  text-decoration: underline;
  margin-bottom: 16px;
}

.text-container:hover .overlay {
  opacity: 0.8;
}

@media (max-width: $tablet-max) {
  .text-container .overlay {
    opacity: 0.8;
    position: static;
  }

  .about-img {
    width: 500px;
    visibility: hidden;
  }

  .overlay {
    height: auto;
  }

  .about {
    height: 710px;
  }
}
