@import "../../variables";

.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact-h1 {
  font-size: 34px;
}

.icon {
  height: 50px;
  width: 50px;
  margin-bottom: 40px;
}

.contact-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 100%;
}

.contact-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 50px;
  }
}

.contact-div {
  display: flex;
}

.details {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  .headers {
    margin: 0;
    color: $sub-text;
    font-family: MomcakeThin;
  }
  .email-link,
  .contact-content {
    color: $main-text;
    font-size: 24px;
    margin: 0;
  }
  .email-link:hover {
    color: $highlighter;
  }
}

.send-message {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
}

.rainbow-animate {
  background: linear-gradient(
    to right,
    #6666ff,
    #0099ff,
    #00ff00,
    #ff3399,
    #6666ff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

.i-twitter {
  -webkit-animation: i-twitter 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: i-twitter 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes i-twitter {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes i-twitter {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.i-linkedin {
  -webkit-animation: i-linkedin 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s
    both;
  animation: i-linkedin 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
}

@-webkit-keyframes i-linkedin {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes i-linkedin {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.i-message {
  -webkit-animation: i-message 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
  animation: i-message 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

@-webkit-keyframes i-message {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes i-message {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.i-phone {
  -webkit-animation: i-phone 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s both;
  animation: i-phone 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s both;
}

@-webkit-keyframes i-phone {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes i-phone {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.contactform {
  display: flex;
  flex-direction: column;
  -webkit-animation: contactform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: contactform 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes contactform {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes contactform {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

.contact-h1 {
  -webkit-animation: contact-h1 2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: contact-h1 2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

@-webkit-keyframes contact-h1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes contact-h1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: $tablet-max) {
  .contact-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
  }

  // .arrow {
  //   transform: rotate(90deg);
  //   margin-top: 10px;
  // }

  .contactform {
    -webkit-animation: contactform 6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
    animation: contactform 6s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }

  @-webkit-keyframes contactform {
    0% {
      -webkit-transform: rotateX(100deg);
      transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 1;
    }
  }
  @keyframes contactform {
    0% {
      -webkit-transform: rotateX(100deg);
      transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 1;
    }
  }
}
