@import "../../variables";

@font-face {
  font-family: "MomcakeBold";
  src: url("../../assets/MomcakeBold-WyonA.otf") format("opentype");
}

@font-face {
  font-family: "MomcakeThin";
  src: url("../../assets/MomcakeThin-9Y6aZ.otf") format("opentype");
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $background;
  color: $main-text;
}

.logo-div {
  background-color: $highlighter;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 10px;
}

.logo {
  font-size: 25px;
}

@media (max-width: $tablet-max) {
  .logo-div {
    height: 35px;
    width: 35px;
  }
  .logo {
    font-size: 20px;
  }
}
