$background: #222323;
$altbackground: #202020;
$main-text: #FEFEFE;
$sub-text: #D0CCCA;
$highlighter: #C86142;
$font-color: #2A293E;;
$font-size: 14pt;
$form-bg: #f8f4e5;
$form-shadow: #05ffbb;
$form-primary-highlight: #95a4ff;
$form-secondary-highlight: #ffc8ff;
$contact-highlight: #ffa580;
$neon-text: #f40;
$neon-border: #08f;
$r : 50px;  // glow radius
$o : 25px;  // glow offset
$d : 2s;    // duration
$chars : 6; // # of letters
$c : cyan yellow tomato;
$mobile-min: 380px;
$mobile-max: 480px;
$tablet-min: 481px;
$tablet-max: 850px;
$laptop-min: 769px;
$laptop-max: 1024px;

// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV