@import "../../variables";

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  h4 {
    font-family: MomcakeThin;
    color: $sub-text;
    margin-top: 0;
  }
}

.about-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: $tablet-max) {
  .hover-text {
    display: none;
  }
}