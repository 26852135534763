@import "../../variables";

.welcome-main {
  display: flex;
  justify-content: space-around;
  font-size: 84px;
  font-family: "MomcakeBold";
  width: 100%;
  .left-main {
    flex: 66%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 100px;
    div {
      .name {
        position: absolute;
        margin: 0;
        padding-top: 150px;
        padding-left: 10px;
      }
      .headshot {
        height: 600px;
        width: 550px;
        src: url("../../assets/headshot_nobackground.png");
        -webkit-animation: headshot 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
        animation: headshot 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }
}

.right-main {
  margin-right: 50px;
  align-self: center;
  width: 75%;
  z-index: 1;
  .title {
    font-size: 20px;
    color: $highlighter;
    text-decoration: underline;
  }
  .welcome-contact {
    color: $highlighter;
    font-size: 18px;
  }
}

.side-section {
  display: flex;
  flex-direction: column;
}

.shapes {
  height: 300px;
  width: 300px;
  margin: 50px 0 -100px 0;
}

.email-link,
.email-link:hover,
.email-link:focus,
.email-link:after {
  text-decoration: none;
  color: $highlighter;
}

.location,
.intro {
  margin: 0;
  font-size: 52px;
}

.entrance {
  -webkit-animation: entrance 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: entrance 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.exit {
  -webkit-animation: exit 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
  animation: exit 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
}

.name {
  -webkit-animation: name 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: name 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes name {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes name {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes entrance {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);

    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);

    opacity: 1;
  }
}

@keyframes entrance {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);

    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);

    opacity: 1;
  }
}

@-webkit-keyframes exit {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);

    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);

    opacity: 1;
  }
}

@keyframes exit {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);

    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);

    opacity: 1;
  }
}

@-webkit-keyframes headshot {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes headshot {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.location {
  -webkit-animation: location 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: location 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes location {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes location {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.intro {
  -webkit-animation: intro 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
  animation: intro 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
}

@-webkit-keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.intro-span {
  -webkit-animation: intro-span 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 2.5s both;
  animation: intro-span 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 2.5s both;
}

@-webkit-keyframes intro-span {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro-span {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.load-last {
  -webkit-animation: load-last 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 3.5s both;
  animation: load-last 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) 3.5s both;
}

@-webkit-keyframes load-last {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes load-last {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: $tablet-max) {
  .welcome-main {
    display: flex;
    flex-direction: column-reverse;
    .left-main {
      justify-content: start;
      align-items: start;
      margin-right: 0;
    }
  }

  .shapes {
    margin: 0 0 -150px 0;
  }

  .side-section {
    margin-top: 25px;
    position: relative;
    .shapes {
      position: absolute;
      right: 0;
      margin-right: -100px;
    }
  }
}
